import './App.css';
import { lazy, Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import useTelegramAuth from "./components/auth/telegramAuth";
import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import AppBar from './components/Appbar/index';
import logo from "./assets/image/logo.png";
import styles from './pages/main/style.module.css';
import History from './pages/orderStory/index'
import Rules from './pages/rules/index'
import OrderDetails from './pages/orderDetails';
import BoxberryTrack from './pages/traking';
import { ClipLoader } from 'react-spinners';
import FAQ from './pages/faq/index'
import About from "./pages/about/index"
import Round from './assets/image/svg/round.svg'
import Round2 from './assets/image/svg/method-draw-image.svg'
import { sendAuthData, validateToken, logUserLogin } from './api/api'; // Импортируем функцию из api.js
import axios from 'axios';


// Лениво загружаем страницы
const Main = lazy(() => import('./pages/main/index'));
const Choice = lazy(() => import('./pages/choice'));
const CalculateCost = lazy(() => import('./pages/calculateCost'));
const OrderInfo = lazy(() => import('./components/orderInfo/index'));
const OrderCompleted = lazy(() => import('./pages/OrderCompleted'));

function App() {
  const { authData: telegramAuthData } = useTelegramAuth();
  const [authStatus, setAuthStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [isTimeout, setIsTimeout] = useState(false); // Новый стейт для проверки таймаута
  const [errorMessage, setErrorMessage] = useState("");
  const [dataAuthErr, setDataAuthErr] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  useEffect(() => {
    // Проверяем куки при загрузке
    const devCookie = Cookies.get("dev");
    if (devCookie === "true") {
      setShowDebugInfo(true); // Если dev=true, показываем блок
    }

    // Добавляем обработчик для сочетания клавиш
    const handleKeyDown = (event) => {
      // Проверяем сочетание Ctrl + Shift + D
      if (event.ctrlKey && event.shiftKey && event.key === "X") {
        setShowDebugInfo((prev) => !prev); // Переключаем видимость блока
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown); // Очищаем слушатель
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    const sendDataToServer = async (retries = 3) => {
      console.log("Запуск функции sendDataToServer...");
      setErrorMessage('Ждёт authData');
      if (!telegramAuthData) {
        console.warn("authData отсутствует, ожидание...");
        return;
      }
      console.log("Проверка telegram_id из куки...");
      setErrorMessage('Проверяет токен');

      // Проверяем наличие telegram_id в куках
      const storedTelegramId = Cookies.get('telegram_id');
      const currentTelegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

      if (!storedTelegramId && currentTelegramId) {
        console.log("telegram_id отсутствует в куках. Сохраняем текущий telegram_id...");
        Cookies.set('telegram_id', currentTelegramId, { expires: 7, secure: true, sameSite: 'None' });
        console.log("telegram_id сохранён:", currentTelegramId);
      } else if (storedTelegramId && storedTelegramId != currentTelegramId) {
        console.log("Несовпадение telegram_id.");
        console.log("Удаляем куки...");
        Cookies.remove('auth_token', { secure: true, sameSite: 'None' });
        Cookies.remove('telegram_id', { secure: true, sameSite: 'None' });
      }

      // Проверяем наличие токена
      const token = Cookies.get('auth_token');
      if (token) {
        console.log("Токен найден в куках. Проверка его валидности...");
        const isValid = await validateToken(token);
        if (isValid) {
          console.log("Токен валиден. Создаем лог входа пользователя...");
          await logUserLogin(token);
          console.log("Авторизация успешна. Устанавливаем статус success...");
          setAuthStatus('success');
          clearTimeout(timeoutId); // Очищаем таймер, если авторизация успешна
          return;
        } else {
          console.log("Токен не валиден. Удаляем куки...");
          Cookies.remove('auth_token', { secure: true, sameSite: 'None' });
          Cookies.remove('telegram_id', { secure: true, sameSite: 'None' });
        }
      }

      try {
        console.log("Отправка запроса на получение нового токена...");
        setErrorMessage('Попытка запроса');
        const data = await sendAuthData({ initData: telegramAuthData }, retries).catch((err) => {
          console.error("Ошибка из запроса:", err.message || err);
          setErrorMessage(err.message || 'Ошибка из запроса');
          throw err;
        });
        console.log("Получен ответ от сервера:", data);

        // Проверяем, что response существует и содержит auth_token
        if (data && data.auth_token) {
          console.log("Токен успешно получен. Сохраняем его в куки...");
          setAuthStatus('success');
          Cookies.set('auth_token', data.auth_token, { expires: 7, secure: true, sameSite: 'None' });
          console.log("Токен сохранён:", data.auth_token);

          // Убедимся, что telegram_id также сохранён
          if (!Cookies.get('telegram_id') && window.Telegram.WebApp.initDataUnsafe?.user?.id) {
            Cookies.set('telegram_id', window.Telegram.WebApp.initDataUnsafe.user.id, { expires: 7, secure: true, sameSite: 'None' });
            console.log("telegram_id сохранён:", window.Telegram.WebApp.initDataUnsafe.user.id);
          }

          console.log("Создаем лог входа пользователя...");
          await logUserLogin(data.auth_token);
          clearTimeout(timeoutId); // Очищаем таймер при успешной авторизации
        } else {
          console.error("Ответ сервера не содержит auth_token:", data);
          setAuthStatus('error');
          setErrorMessage(errorMessage + ' Неудачная авторизация: auth_token отсутствует');
        }
      } catch (error) {
        console.error("Ошибка при отправке данных:", error.message || error);
        if (retries > 0) {
          console.log(`Повторная попытка (${retries})...`);
          sendDataToServer(retries - 1); // Рекурсивный вызов с уменьшением количества попыток
        } else {
          console.error("Все попытки завершились неудачей. Устанавливаем статус ошибки...");
          setAuthStatus('error');
          setErrorMessage(errorMessage + ' Error добавляется в catch: ' + error.message);
        }
      }
    };

    // Устанавливаем таймер на 10 секунд
    console.log("Установка таймера на 10 секунд...");
    timeoutId = setTimeout(() => {
      console.log("Таймер сработал. Проверка authStatus...");
      if (authStatus === 'loading') {
        console.error("Таймер истёк. Устанавливаем статус ошибки.");
        setIsTimeout(true);
        setAuthStatus('error');
        setErrorMessage((prev) => prev + ' Таймер истёк');
      }
    }, 10000);

    // Запускаем процесс авторизации
    console.log("Запускаем sendDataToServer...");
    sendDataToServer();

    return () => {
      console.log("Очистка таймера при размонтировании компонента...");
      clearTimeout(timeoutId); // Очищаем таймер при размонтировании компонента
    };
  }, [telegramAuthData]);

  const pageVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0, transition: { duration: 0.4 } },
    exit: { opacity: 0, x: 20, transition: { duration: 0.3 } },
  };

  // Получаем все куки
  const removeAllCookies = Cookies.get();

  // Перебираем каждую куку и удаляем её
  const handleRemoveCookies = () => {
    for (const cookieName in removeAllCookies) {
      if (removeAllCookies.hasOwnProperty(cookieName)) {
        Cookies.remove(cookieName);
      }
    }
  }

  return (
    <div className={styles.container}>
      <img className={styles.decorBackground1} src={Round} />
      <img className={styles.decorBackground2} src={Round2} />
      {/* {location.pathname} */}
      {showDebugInfo && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            zIndex: 1000,
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <p style={{ color: "green", width: 300 }}>DataAuthErr: {dataAuthErr}</p>
          <p style={{ color: "red" }}>ErrorMessage: {errorMessage}</p>
          <p style={{ color: "red" }}>IsTimeout: {isTimeout ? "Yes" : "No"}</p>
          <p style={{ color: "red" }}>AuthStatus: {authStatus}</p>
          <p><button onClick={handleRemoveCookies
          }>Очистить куки!</button></p>
        </div>
      )}
      <img onClick={() => { navigate('/') }} className={styles.logoImg} src={logo} alt="Логотип" />
      <div className={styles.menuContainer}>
        <Suspense fallback={
          <div className={styles.loadingContainer}>
            <ClipLoader
              color="#36d7b7" // Цвет индикатора
              size={50}       // Размер (в пикселях)
              loading={true}  // Состояние загрузки
            />
          </div>
        }>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={
                  <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={pageVariants}
                  >
                    {authStatus === 'loading' ? (
                      <p className={styles.loadingText + ' ' + styles.loadingAnimation}>⏳ Авторизация...</p>
                    ) : authStatus === 'error' || isTimeout ? (
                      <div className={styles.errorContainer}>
                        <p className={styles.errorText}>🚫 Войти можно только через Telegram</p>
                        <a
                          href="https://t.me/naposilkah_bot"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.telegramButton}
                        >
                          телеграм-бот <b>📦НА ПОСЫЛКАХ📦</b>
                        </a>

                      </div>
                    ) : (
                      <Main />
                    )}
                  </motion.div>
                }
              />
              <Route
                path="/choice"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <Choice />
                  </motion.div>
                }
              />
              <Route
                path="/boxberry"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <CalculateCost />
                  </motion.div>
                }
              />
              <Route
                path="/cdek"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <CalculateCost />
                  </motion.div>
                }
              />
              <Route
                path='/orderinfo'
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <OrderInfo />
                  </motion.div>
                }
              />
              <Route
                path='/faq'
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <FAQ />
                  </motion.div>
                }
              />
              <Route
                path='/about'
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <About />
                  </motion.div>
                }
              />
              <Route
                path='/trackBoxberry'
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <BoxberryTrack />
                  </motion.div>
                }
              />
              <Route
                path="/confirm_order/:id/boxberry"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <OrderCompleted />
                  </motion.div>
                }
              />
              <Route
                path="/history"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <History />
                  </motion.div>
                }
              />
              <Route
                path="/rules"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <Rules />
                  </motion.div>
                }
              />
              <Route
                path="/order/:id"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <OrderDetails />
                  </motion.div>
                }
              />
              <Route
                path="*"
                element={
                  <motion.div initial="initial" animate="animate" exit="exit" variants={pageVariants}>
                    <h1>404</h1>
                  </motion.div>
                }
              />
            </Routes>
          </AnimatePresence>
        </Suspense>
        {location.pathname !== '/' && <AppBar />}
      </div>
    </div>
  );
}

export default App;