import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styles from './style.module.css';
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from 'react-router-dom';
import { fetchOrders as fetchOrdersApi, searchOrders as searchOrdersApi } from '../../api/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DeliveryPack from '../../data/boxes.json';

dayjs.extend(utc);
dayjs.extend(timezone);

const boxes = DeliveryPack;

const HistoryForm = () => {
    const [orders, setOrders] = useState([]);
    const [searchResults, setSearchResults] = useState([]); // <-- добавлено
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [activeFilter, setActiveFilter] = useState('all');
    const [timers, setTimers] = useState({});
    const telegramId = Cookies.get('telegram_id');
    const authToken = Cookies.get('auth_token');
    const navigate = useNavigate();

    const fetchOrders = async (page) => {
        if (!telegramId || !authToken) {
            setError('Необходимо авторизоваться.');
            setLoading(false);
            return;
        }
        try {
            const data = await fetchOrdersApi(telegramId, authToken, page);
            if (data.results && data.results.length === 0) {
                setHasMore(false);
            } else {
                const sortedOrders = data.results.sort((a, b) => b.id - a.id);
                setOrders(prevOrders => [...prevOrders, ...sortedOrders]);
            }
            setLoading(false);
        } catch (err) {
            setError('Ошибка при загрузке заказов.');
            setLoading(false);
        }
    };

    const searchOrders = async (query) => {
        if (!telegramId || !authToken) {
            setError('Необходимо авторизоваться.');
            setLoading(false);
            return;
        }
        try {
            setIsSearching(true);
            const data = await searchOrdersApi(telegramId, authToken, query);
            console.log('Search results:', data);
            setSearchResults(data || []);
        } catch (err) {
            setError('Ошибка при поиске заказов.');
        } finally {
            setLoading(false);
        }
    };

    const isCancelledOrder = (order) => {
        if (order.status === 'Новое отправление') {
            const createdAt = dayjs(order.created_at).tz('Europe/Moscow');
            const currentTime = dayjs().tz('Europe/Moscow');
            const minutesDifference = currentTime.diff(createdAt, 'minute');
            return minutesDifference > 30; // Таймер увеличен до 30 минут
        }
        return false;
    };

    const filteredOrders = () => {
        if (isSearching) return searchResults;

        if (activeFilter === 'all') {
            return orders.filter(
                (order) =>
                    order.archive === false &&
                    !(order.status === 'Новое отправление' && isCancelledOrder(order)) &&
                    order.status !== 'Отменен'
            );
        } else if (activeFilter === 'archive') {
            return orders.filter((order) => order.archive === true);
        } else if (activeFilter === 'cancelled') {
            return orders.filter(
                (order) =>
                    order.status === 'заказ отменён' ||
                    order.status === 'Отменен' ||
                    isCancelledOrder(order)
            );
        }

        return [];
    };

    useEffect(() => {
        if (!telegramId || !authToken) {
            setError('Необходимо авторизоваться.');
            setLoading(false);
            return;
        }
        fetchOrders(page);
    }, [telegramId, authToken, page]);

    useEffect(() => {
        const interval = setInterval(() => {
            setOrders((prevOrders) => {
                return prevOrders.map((order) => {
                    if (order.status === 'Новое отправление' && isCancelledOrder(order)) {
                        // Перемещаем заказ в категорию "Отменённые"
                        return { ...order, status: 'Отменен' };
                    }
                    return order;
                });
            });

            setTimers((prevTimers) => {
                const updatedTimers = {};
                const relevantOrders = isSearching ? searchResults : filteredOrders();
                relevantOrders.forEach((order) => {
                    if (order.status === 'Новое отправление') {
                        const createdAt = dayjs(order.created_at).tz('Europe/Moscow');
                        const currentTime = dayjs().tz('Europe/Moscow');
                        const timeDifferenceMs = 30 * 60 * 1000 - currentTime.diff(createdAt);
                        if (timeDifferenceMs > 0) {
                            updatedTimers[order.id] = timeDifferenceMs;
                        }
                    }
                });
                return updatedTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [orders, searchResults, activeFilter, isSearching]);

    const formatTime = (ms) => {
        const hours = Math.floor(ms / (1000 * 60 * 60));
        const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((ms % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleSearch = (event) => {
        const value = event.target.value.trim();
        setSearchQuery(value);
        if (value === '') {
            setIsSearching(false);
            setSearchResults([]);
            setOrders([]);
            setPage(1);
            fetchOrders(1);
        } else {
            searchOrders(value);
        }
    };

    const displayedOrders = isSearching ? searchResults : filteredOrders();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.searchBar}>
                    <input
                        className={styles.input}
                        type="text"
                        placeholder="Введите номер отправления"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <span className={styles.searchIcon}>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M21 21L16.65 16.65" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
                <div className={styles.filterButtons}>
                    <button
                        className={`${styles.filterButton} ${activeFilter === 'all' ? styles.active : ''}`}
                        onClick={() => setActiveFilter('all')}
                    >
                        Все
                    </button>
                    <button
                        className={`${styles.filterButton} ${activeFilter === 'archive' ? styles.active : ''}`}
                        onClick={() => setActiveFilter('archive')}
                    >
                        Архив
                    </button>
                    <button
                        className={`${styles.filterButton} ${activeFilter === 'cancelled' ? styles.active : ''}`}
                        onClick={() => setActiveFilter('cancelled')}
                    >
                        Отменённые
                    </button>
                </div>
            </div>

            {isSearching && (
                <button
                    className={styles.resetButton}
                    onClick={() => {
                        setSearchQuery('');
                        setIsSearching(false);
                        setSearchResults([]);
                        setOrders([]);
                        setPage(1);
                        fetchOrders(1);
                    }}
                >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px' }}>
                        <path d="M19 12H5M12 19L5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Вернуться к истории заказов
                </button>
            )}

            <h3 className={styles.part}>
                {isSearching ? 'Результат поиска' : 'История отправлений'}
            </h3>

            <ul className={styles.orderList}>
                {displayedOrders.length > 0 ? (
                    displayedOrders.map(order => {
                        const showTimer = order.status === 'Новое отправление' && timers[order.id] > 0;
                        return (
                            <li key={order.id} className={styles.orderItem}>
                                <strong className={styles.orderTitle}>Заказ №{order.order_id || 'заказ отменён'}</strong>

                                <div className={styles.orderGroup}>
                                    <div className={styles.row}>
                                        <span className={styles.label}>Дата:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span>
                                            {order.created_at
                                                ? dayjs(order.created_at).tz('Europe/Moscow').format('DD.MM.YYYY HH:mm:ss')
                                                : 'Неизвестна'
                                            }
                                        </span>
                                    </div>

                                    <div className={styles.row}>
                                        <span className={styles.label}>Размер:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span>{order.height && order.width && order.length ? `${order.height}×${order.width}×${order.length} см` : '—'}</span>
                                    </div>

                                    <div className={styles.row}>
                                        <span className={styles.label}>Упаковка:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span>{order.dimensions === 'Короб' ? 'Картонная коробка' : (order.dimensions || '—')}</span>
                                    </div>

                                    <div className={styles.row}>
                                        <span className={styles.label}>Вес:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span>{order.weight != null ? `${order.weight} г` : '—'}</span>
                                    </div>

                                    <div className={styles.row}>
                                        <span className={styles.label}>Стоимость:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span className={styles.priceValue}>{order.price || '0'} ₽</span>
                                    </div>

                                    <div className={styles.row}>
                                        <span className={styles.label}>Трек:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span className={styles.trackNumber}>{order.track_number || 'отсутствует'}</span>
                                    </div>

                                    <div className={styles.row}>
                                        <span className={styles.label}>Статус:</span>
                                        <span className={styles.dotsSeparator}></span>
                                        <span className={styles.statusBadge}>{order.status || 'заказ отменён'}</span>
                                    </div>
                                </div>

                                {showTimer && (
                                    <div className={styles.timer}>
                                        Время до отмены: {formatTime(timers[order.id])}
                                    </div>
                                )}

                                {order.status === 'Новое отправление' && !isCancelledOrder(order) && (
                                    <button className={styles.payButton}
                                        onClick={() => { window.location.href = order.payment_url }}>
                                        Оплатить
                                    </button>
                                )}

                                {order.status === 'Создан и оплачен' && (
                                    <button className={styles.detailsButton}
                                        onClick={() => navigate(`/order/${order.order_id}`)}>
                                        Полная информация
                                    </button>
                                )}
                            </li>

                        );
                    })
                ) : (
                    <li className={styles.noResults}>Нет результатов</li>
                )}
                {loading && <li className={styles.loader}>Загрузка...</li>}
            </ul>
        </div>
    );
};

export default HistoryForm;