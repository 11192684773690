import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import styles from './style.module.css';
import { useParams } from 'react-router';
import BoxberryImg from '../../assets/image/choice/boxberryGray.png';
import CdekImg from '../../assets/image/choice/cdekgray.png';
import BarcodeComponent from '../../components/barCode/inde';
import { QRCodeCanvas } from "qrcode.react";
import { fetchOrderDetails as fetchOrderDetailsApi } from '../../api/api'; // Импортируем функцию из api.js
import DeliveryPack from '../../data/boxes.json';
import { orderArchive as FetchArchiveApi } from '../../api/api';
import classNames from 'classnames';

const boxes = DeliveryPack;

const OrderDetails = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isArchive, setIsArchive] = useState(null)
    const { id } = useParams(); // Получаем ID заказа из URL
    const telegramId = Cookies.get('telegram_id');
    const authToken = Cookies.get('auth_token');

    const fetchAddToArchive = async () => {
        try {
            const data = await FetchArchiveApi(authToken, telegramId, id)
            console.log(data.archive ? "Перемещен в архив" : "Возвращено из архива");
            setIsArchive(data.archive)
        } catch (error) {
            alert('Ошибка при перемещении заказа в архив.');
        }
    };

    useEffect(() => {
        if (!telegramId || !authToken) {
            setError('Необходимо авторизоваться.');
            setLoading(false);
            return;
        }

        const fetchOrder = async () => {
            try {
                const data = await fetchOrderDetailsApi(telegramId, id, authToken);
                setOrder(data);
                setIsArchive(data.archive)
                console.log('====================================');
                console.log(data);
                console.log('====================================');
                setLoading(false);
            } catch (err) {
                setError('Ошибка при загрузке заказа.');
                setLoading(false);
                console.error(err);
            }
        };

        fetchOrder();
    }, [telegramId, authToken, id]);

    if (loading) return <p>Загрузка...</p>;
    if (error) return <p>{error}</p>;
    if (!order) return <p>Заказ не найден</p>;

    return (
        <div className={styles.orderDetails}>
            {/* Шапка с лого и кнопкой */}
            <div className={styles.header}>
                <img
                    width={150}
                    src={order.company === 'boxberry' ? BoxberryImg : CdekImg}
                    alt="Логотип службы доставки"
                    className={styles.logo}
                />
                <button
                    className={classNames(
                        styles.archiveButton,
                        isArchive ? styles.archiveBtnColorRed : styles.archiveBtnColorBlue
                    )}
                    onClick={fetchAddToArchive}
                >
                    {isArchive ? 'Вернуть из архива' : "Переместить в архив"}
                </button>
            </div>

            {/* Основной контент */}
            <div className={styles.orderItem}>
                <h3 className={styles.orderTitle}>
                    Детальная информация о заказе №{order.order_id}
                </h3>

                {/* Блоки информации с новой структурой */}
                <div className={styles.gridContainer}>
                    {/* Блок деталей заказа */}
                    <div className={styles.dataBlock}>
                        <h3>Информация о заказе</h3>
                        <div className={styles.infoRow}>
                            <span>Дата заказа:</span>
                            <p>{dayjs(order.created_at).format('DD.MM.YYYY HH:mm:ss')}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Статус:</span>
                            <p className={styles.status}>{order.status || 'заказ отменён'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Стоимость:</span>
                            <p className={styles.price}>{order.price || '0'} руб.</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Трек-номер:</span>
                            <p className={styles.trackNumber}>{order.track_number || 'Нет данных'}</p>
                        </div>
                    </div>

                    {/* Блок доставки */}
                    <div className={styles.dataBlock}>
                        <h3>Доставка</h3>
                        <div className={styles.infoRow}>
                            <span>Срок доставки:</span>
                            <p>{order.weight != null ? `${order.delivery_period} дней` : 'Неизвестно'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Вес посылки:</span>
                            <p>{order.weight != null ? `${order.weight} грамм` : 'Неизвестно'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Размер посылки:</span>
                            <p>{order.height && order.width && order.length ? `${order.height}x${order.width}x${order.length} см.` : 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Ценность:</span>
                            <p>{order.declared_price != null ? `${order.declared_price} руб.` : 'Неизвестно'}</p>
                        </div>
                    </div>

                    {/* Блок отправителя */}
                    <div className={styles.dataBlock}>
                        <h3>Отправитель</h3>
                        <div className={styles.infoRow}>
                            <span>Ф.И.О:</span>
                            <p>{order.sender_full_name || 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Телефон:</span>
                            <p>+7{order.sender_phone || 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Email:</span>
                            <p>{order.sender_email || 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Адрес:</span>
                            <p>{order.targetstart_address || 'Нет данных'}</p>
                        </div>
                    </div>

                    {/* Блок получателя */}
                    <div className={styles.dataBlock}>
                        <h3>Получатель</h3>
                        <div className={styles.infoRow}>
                            <span>Ф.И.О:</span>
                            <p>{order.recipient_full_name || 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Телефон:</span>
                            <p>+7{order.recipient_phone || 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Email:</span>
                            <p>{order.recipient_email || 'Нет данных'}</p>
                        </div>
                        <div className={styles.infoRow}>
                            <span>Адрес:</span>
                            <p>{order.target_address || 'Нет данных'}</p>
                        </div>
                    </div>
                </div>

                {/* Штрих-код и QR-код */}
                <div className={styles.codesSection}>
                    <div className={styles.barcodeBlock}>
                        <h3>Штрих-код на отправку</h3>
                        <BarcodeComponent value={order.order_id} />
                    </div>
                    <div className={styles.qrcodeBlock}>
                        <h3>QR-code - этикетка посылки</h3>
                        <QRCodeCanvas value={order.label_url} size={200} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetails;